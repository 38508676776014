<template>
  <news-article-card>
    <nuxt-link
      :to="linkToArticle"
      class="news-article__inner h-100 position-relative d-flex flex-column justify-content-center position-relative py-16 px-4 p-md-8 text-decoration-none text-white cursor-pointer"
      :class="{ 'news-article--full-width-mobile': fullWidthOnMobile }"
    >
      <strapi-image-overlay
        class="news-article__background w-100"
        :image="article.teaserImage || article.headerCoverImage"
        :alt="article.title"
        as-background
        rounded
      />
      <div
        class="news-article__badge badge position-absolute bg-midnight-2 fs-6 text-capitalize"
      >
        {{ article.type }}
      </div>
      <p class="mb-0 fs-5 fw-bold text-uppercase">
        <span class="text-rhino">
          {{ publishedAt }}
        </span>
        <template v-if="article.category !== 'None'">
          &nbsp;/&nbsp;
          {{ article.category }}</template
        >
      </p>
      <h2 class="fs-1">{{ article.title }}</h2>
      <p class="col-12 col-md-9 fs-5">{{ article.teaserText }}</p>
      <div class="news-article__link btn btn-lg btn-primary">
        {{ $t('homePage/readMore') }}
      </div>
    </nuxt-link>
  </news-article-card>
</template>

<script setup lang="ts">
import type { StrapiArticle } from '~/apollo/types/types';
import {
  useArticleLink,
  useArticlePublishedDate,
} from './features/useArticleFeatures';

const props = defineProps({
  article: {
    required: true,
    type: Object as PropType<StrapiArticle>,
  },
  fullWidthOnMobile: {
    type: Boolean,
    default: false,
  },
});

const { publishedAt } = useArticlePublishedDate(props.article);
const { linkToArticle } = useArticleLink(props.article);
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors';

.news-article {
  &__inner {
    background: linear-gradient(
      to right,
      rgba($gp-midnight-gradient-b, 1),
      rgba($gp-midnight-gradient-b, 0.8) 60%,
      rgba($gp-midnight-gradient-b, 0) 100%
    );
    border-radius: 5px;
  }

  &--full-width-mobile {
    border-radius: 0;

    .news-article__background {
      border-radius: 0;
    }
  }

  &__background {
    border-radius: 5px;
  }

  @include media-breakpoint-up(md) {
    border-radius: 5px !important;

    &__background {
      border-radius: 5px !important;
    }
  }

  &__badge {
    top: 1.5rem;

    @include media-breakpoint-up(md) {
      top: 1rem;
    }
  }

  &__link {
    width: fit-content;
  }
}
</style>
