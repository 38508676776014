<template>
  <div class="row">
    <div
      :key="articles[0].id"
      class="col-12"
      :class="topArticleHasFullWidthMobile ? 'p-0 px-md-4' : 'px-4'"
    >
      <news-top-article
        :article="articles[0]"
        class="news__article news__article--top"
        :full-width-mobile="topArticleHasFullWidthMobile"
      />
    </div>
    <div
      v-for="article in articles.slice(1)"
      :key="article.id"
      class="col-12 col-md-4"
    >
      <news-article :article="article" class="news__article" />
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiArticle } from '~/apollo/types/types';

export default defineComponent({
  props: {
    articles: {
      type: Array as PropType<Array<StrapiArticle>>,
      default: () => [],
    },
    topArticleHasFullWidthMobile: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.news__article {
  min-height: 20rem;

  @include media-breakpoint-between(md, lg) {
    min-height: 15rem;
  }

  &--top {
    min-height: 32rem;

    @include media-breakpoint-up(md) {
      min-height: 25rem;
    }
  }
}
</style>
