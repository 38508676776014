import { StrapiArticleType } from '~/apollo/types/types';
import type SelectItem from '~/components/form/dropdown/types';

export enum StrapiArticleTypeAll {
  All = 'all',
}

export const useNewsTypes = () => {
  const { t } = useI18n();
  const newsTypeOptions = computed<Array<SelectItem>>(() => [
    {
      key: StrapiArticleTypeAll.All,
      label: t('general/non_special_texts/all_items').toString(),
    },
    {
      key: StrapiArticleType.News,
      label: t('general/non_special_texts/news_plural').toString(),
    },
    {
      key: StrapiArticleType.Blog,
      label: t('news/blog').toString(),
    },
  ]);

  const currentNewsType = ref<SelectItem>(newsTypeOptions.value[0]);

  const currentNewsTypeForGql = computed(() =>
    currentNewsType.value.key === StrapiArticleTypeAll.All
      ? undefined
      : (currentNewsType.value.key as StrapiArticleType),
  );

  return {
    newsTypeOptions,
    currentNewsType,
    currentNewsTypeForGql,
  };
};

export default useNewsTypes;
