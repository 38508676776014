<template>
  <div class="news-article h-100 rounded">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';

.news-article {
  border: 2px solid transparent;
  transition: border-color 0.5s ease;

  &:hover {
    border-color: $primary;
  }
}
</style>
