<template>
  <news-article-card>
    <nuxt-link
      :to="linkToArticle"
      class="news-article__inner position-relative h-100 d-flex flex-column justify-content-end text-decoration-none text-white p-4 rounded"
    >
      <strapi-image-overlay
        class="news-article__background"
        :image="article.thumbnailImage"
        :alt="article.title"
        as-background
        rounded
      />
      <div
        class="news-article__badge badge position-absolute bg-midnight-2 fs-6 text-capitalize"
      >
        {{ article.type }}
      </div>
      <div class="news-article__content">
        <p class="mb-0 fw-bold text-uppercase">
          <span class="text-rhino">
            {{ publishedAt }}
          </span>
          <template v-if="article.category !== 'None'">
            &nbsp;/&nbsp;
            {{ article.category }}</template
          >
        </p>
        <h2 class="fs-4">
          {{ article.title }}
          <fa-icon :icon="faCircleArrowRight" class="text-primary ms-2 fs-5" />
        </h2>
      </div>
    </nuxt-link>
  </news-article-card>
</template>

<script setup lang="ts">
import type { StrapiArticle } from '~/apollo/types/types';
import {
  useArticleLink,
  useArticlePublishedDate,
} from './features/useArticleFeatures';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
  article: {
    required: true,
    type: Object as PropType<StrapiArticle>,
  },
});

const { publishedAt } = useArticlePublishedDate(props.article);
const { linkToArticle } = useArticleLink(props.article);
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';

.news-article {
  &__inner {
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      rgba(196, 204, 169, 0) -12%,
      rgba(79, 66, 86, 0.89) 79%,
      #31223b
    );

    @include media-breakpoint-between(md, lg) {
      background: linear-gradient(
        to bottom,
        rgba(196, 204, 169, 0) -12%,
        rgba(79, 66, 86, 0.89) 50%,
        #31223b 100%
      );
    }
  }

  &__content {
    min-height: 30%;

    @include media-breakpoint-up(md) {
      min-height: 60%;
    }

    @include media-breakpoint-up(lg) {
      min-height: 40%;
    }
  }

  &__badge {
    top: 1rem;
  }
}
</style>
